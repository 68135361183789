<template>
    <div class="md:h-[19.55rem] mb-5 md:mb-10 lg:mb-20 space-y-4">
    <div >
        <h1 class="flex justify-center pt-10 mt-10 text-xl font-bold md:text-2xl lg:text-3xl md:mt-10 lg:mt-20" style="transition:all 1s ease-in-out">Contact du restaurant Le TEM</h1>
        <h2 class="flex justify-center text-center mt-4 text-sm font-thin uppercase text-yellow-800 md:text-2xl lg:text-3xl md:mt-6 lg:mt-10" style="transition:all 1s ease-in-out">Contacter notre restaurant pour toutes questions ou réservation</h2>
    </div>
    <div class="flex justify-center items-center h-14 w-full mb-8 space-x-8">
        <button @click="showNumber()" class="rounded h-full w-28 md:w-44 bg-gray-800 hover:bg-gray-700 active:bg-gray-900 text-white font-light text-sm md:text-lg md:tracking-widest md:px-2 flex justify-center items-center"><span v-if="!shownumber">Téléphone</span><span v-else>03 83 32 52 62</span></button>
        <a class="rounded h-full w-28 md:w-44 bg-gray-800 hover:bg-gray-700 active:bg-gray-900 text-white font-light text-sm md:text-lg md:tracking-widest md:px-2 flex justify-center items-center" href="mailto:reservation@drdh.fr">Email</a>
    </div>
</div>
</template>

<script>
    export default {
        data() {
            return {
                shownumber: false,
            }
        },
        methods: {
            showNumber() {
                this.shownumber = !this.shownumber
            }
        }
    }

</script>